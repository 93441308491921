import React from 'react';

import { Grid } from '@mui/material';
import { useTranslation } from 'react-i18next';
import CollegeMembershipActiveMembershipsTab from 'src/components/college-membership-active-memberships-tab/college-membership-active-memberships-tab';
import TabPanel from 'src/components/tab-panel/tab-panel';
import Tabs from 'src/components/tabs/tabs';

const CollegeMembershipTabs: React.FC = () => {
  const { t } = useTranslation();
  const [value, setValue] = React.useState(0);

  const handleChange = (event: React.SyntheticEvent<{}>, newValue: number) => {
    setValue(newValue);
  };

  return (
    <>
      <Tabs value={value} labels={[t('membership_plural')]} handleChange={handleChange} />
      <Grid item xs={12}>
        <TabPanel value={value} index={0}>
          <CollegeMembershipActiveMembershipsTab />
        </TabPanel>
      </Grid>
    </>
  );
};

export default CollegeMembershipTabs;
