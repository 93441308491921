// extracted by mini-css-extract-plugin
export var bodyBase = "college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodyLarge = "college-membership-active-memberships-tab-module--body-large--b1328 college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodyLargeBold = "college-membership-active-memberships-tab-module--body-large-bold--fe997 college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodyRegular = "college-membership-active-memberships-tab-module--body-regular--91c0a college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodyRegularBold = "college-membership-active-memberships-tab-module--body-regular-bold--c40b0 college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodySmall = "college-membership-active-memberships-tab-module--body-small--215ee college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var bodySmallBold = "college-membership-active-memberships-tab-module--body-small-bold--d42d6 college-membership-active-memberships-tab-module--body-base--96998 college-membership-active-memberships-tab-module--site-font--1b18f";
export var borderTop = "college-membership-active-memberships-tab-module--border-top--5431b";
export var breakWordContainer = "college-membership-active-memberships-tab-module--break-word-container--70426";
export var buttonIconBase = "college-membership-active-memberships-tab-module--button-icon-base--5fcf2";
export var clickLink = "college-membership-active-memberships-tab-module--click-link--1a88a";
export var dropdownBase = "college-membership-active-memberships-tab-module--dropdown-base--b6964";
export var dropdownSelectBase = "college-membership-active-memberships-tab-module--dropdown-select-base--0c5f5 college-membership-active-memberships-tab-module--text-input--2531e";
export var flexCol = "college-membership-active-memberships-tab-module--flex-col--ab980";
export var formErrorMessage = "college-membership-active-memberships-tab-module--form-error-message--64837";
export var h3 = "college-membership-active-memberships-tab-module--h3--c59b1";
export var h4 = "college-membership-active-memberships-tab-module--h4--67c36";
export var headerContainer = "college-membership-active-memberships-tab-module--headerContainer--f7a6e";
export var hoverLink = "college-membership-active-memberships-tab-module--hover-link--75540";
export var hoverRow = "college-membership-active-memberships-tab-module--hover-row--bc420";
export var membershipContainer = "college-membership-active-memberships-tab-module--membership-container--4bed5 college-membership-active-memberships-tab-module--flex-col--ab980 college-membership-active-memberships-tab-module--primary-border--f62a8";
export var membershipHeader = "college-membership-active-memberships-tab-module--membership-header--b240f";
export var membershipHeading = "college-membership-active-memberships-tab-module--membership-heading--8db0f";
export var membershipLabel = "college-membership-active-memberships-tab-module--membership-label--72eee";
export var moreFiltersBorderClass = "college-membership-active-memberships-tab-module--more-filters-border-class--dcdd3";
export var pageBg = "college-membership-active-memberships-tab-module--page-bg--35522";
export var pointer = "college-membership-active-memberships-tab-module--pointer--e4c89";
export var primaryBorder = "college-membership-active-memberships-tab-module--primary-border--f62a8";
export var shadowBoxLight = "college-membership-active-memberships-tab-module--shadow-box-light--4285d";
export var siteFont = "college-membership-active-memberships-tab-module--site-font--1b18f";
export var slideDownAndFade = "college-membership-active-memberships-tab-module--slideDownAndFade--2eae8";
export var slideLeftAndFade = "college-membership-active-memberships-tab-module--slideLeftAndFade--54d12";
export var slideRightAndFade = "college-membership-active-memberships-tab-module--slideRightAndFade--68a7c";
export var slideUpAndFade = "college-membership-active-memberships-tab-module--slideUpAndFade--da79f";
export var statusDecoration = "college-membership-active-memberships-tab-module--status-decoration--a28e6";
export var textInput = "college-membership-active-memberships-tab-module--text-input--2531e";
export var textInverted = "college-membership-active-memberships-tab-module--text-inverted--d24d4";
export var textMediumDark = "college-membership-active-memberships-tab-module--text-medium-dark--069af";
export var tooltipFont = "college-membership-active-memberships-tab-module--tooltipFont--7a579";
export var unstyledButton = "college-membership-active-memberships-tab-module--unstyled-button--1ca1f";